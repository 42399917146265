import React from "react";
import images1 from "./images/medium-shot-smiley-man-working_23-2149633863 (1).avif"
import images2 from "./images/IMG_7570-2048x1477.jpg"

function Services () {
    return(
        <section>
        <div className="lg:flex grid place-items-center gap-2   mt-20 xl:gap-8 xl:mx-8  lg:mx-2  justify-center">
        <div className="lg:w-900  w-90% ">
            <img src={images1} className="service-image lg:h-255 w-full   xl:h-98 xl:w-full lg:w-100 "/>
        </div>
         
          
        <div className=" lg:w-900  mt-12 lg:mt-0 md:place-items-start grid place-items-center w-90% md:w-90% md:flex">
            <div className="bg-white px-8 pt-8  md:h-75 xl:h-98 lg:h-255 md:pb-0  pb-4  md:rounded-tl-tl-20  md:rounded-bl-tl-20 md:rounded-tr-none rounded-tr-tr-20  rounded-tl-tr-20 w-full  md:w-900 ">
                <h2 className="text-black mb-4  font-bold text-xl">Our Mission</h2>
                <p className=" leading-loose text-base text-gray-700  font-semibold"> Our hospital provides essential health care services for pregnant women, mothers and children. These services ensure that we safeguard health and life and prevent diseases. We prioritise care for our mothers and children, administering vaccines with professional expertise and compassion.</p>
                </div>
            <div className="bg-orange-600 xl:h-98  md:h-75   lg:h-255 pt-8 px-8  md:rounded-tr-tl-20  md:rounded-br-tl-20  w-full  md:w-900 ">
                <h2 className="text-white mb-4  font-bold text-xl">Our Vision</h2>
                <p className="mb-4 lg:mb-0 leading-loose text-white font-medium text-base"> Our vision is to ensure comprehensive and affordable medical care for pregnant women, immunization for children, post-natal care for nursing mothers thereby fostering a healthy community through preventive care, expert services and compassionate support for every family. </p>
                </div>
        </div>
        
        
        </div>


       
     <section className=" grid place-items-center   ">
      <div className="lg:flex grid place-items-center lg:place-items-start mt-40   gap-8  w-90%  lg:w-95%   justify-between ">
        <section className="  w-full   ">
            <img src={images2} className="    w-full  rounded-2xl "/>
        </section>
        <section className="w-full  " id="about">
          <h2 className="md:text-4xl text-2xl text-black font-bold  leading-normal mb-4">Experience Our patient-centered care.</h2>  
          <p className="text-gray-700 leading-loose font-bold  text-base mb-8">We are a 30 bed health facility. Over the years, BONNE  SANTÉ HEALTH SERVICES LTD has expanded to become a first class health care provider offering a wide range of services and the preferred medical facility for women who come from  far and wide for good medical services.
</p>
          <p className="text-gray-700 leading-loose  font-bold  text-base mb-8 " >Our reputation as one of the leading healthcare providers was confirmed with our 100 percent safe delivery operations for C-section deliveries and our provisions of free immunization for children to enable them stay healthier and stronger. </p>
          <p className="text-gray-700 leading-loose  font-bold  text-base mb-8 ">Our highly skilled medical staff strength comprises full time consultants, several visiting consultants in different specialties, Registrars, Medical Officers, House Officers and over  over 30 qualified nurses. Our healthcare professionals maintain a strong focus on quality care and constantly ensure their commitment to patient’s safety.</p>
        </section>
      </div>
      </section>


        </section>
    )
}

export default Services;