import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
function Testimonies(){
    return(
        <div className="w-full lg:mt-32 mt-8 grid place-items-center">
            <h2 className="md:text-4xl text-2xl text-black font-bold  leading-normal mb-24 ">What People Say About Us</h2>
            
            <section className="lg:flex grid place-items-center  lg:place-items-start gap-6 xl:gap-10">
       
            <div className="    lg:w-80  w-90% lg:h-97  shadow-md shadow-black service-orange service-white bg-white px-8 pt-10">
                <div className="flex">
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            </div>
            <p className="mt-6 font-bold text-base text-gray-600   leading-loose">One thing I really liked about the hospital was the way the nurses were always available whenever you needed them without complaints and they were very friendly</p>
            <p className="font-bold tracking-wider mb-10 mt-5 text-gray-600 text-base">HARBIESTICTCHES</p>
            </div>

            <div className="shadow-md  lg:w-80 lg:h-97  w-90%   service-orange service-white shadow-black bg-white px-8 pt-10">
            <div className="flex">
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            </div>
            <p className="font-bold text-base leading-loose mt-6 text-gray-600"> I have been admitted here before and I have to say that their services are top notch with good equipment and conducive environment. You won't go there and leave with another illness because everywhere is so clean.
 </p>
            <p className="font-bold tracking-wider mt-5 text-base  mb-10 text-gray-600">DAMILOLA OLAYEMI</p>
            </div>
            <div className="   lg:w-80  w-90%  lg:h-97 shadow-md service-orange service-white shadow-black bg-white px-8 pt-10">
            <div className="flex">
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            <span className="text-blue-500   "  >   <FontAwesomeIcon icon={faStar} /></span>
            </div>
            
            <p className="font-bold text-base leading-loose mt-6  text-gray-600">BONNE SANTÉ HEALTH SERVICE LIMITED has raised the bar so high that I cannot settle for less when it comes to healthcare having spent about 5 days there in the last week, all i can say is there sevices is very good.
AGBENIGA OMOBOLANLE</p>
<p className="font-bold tracking-wider mt-5 text-base  mb-10 text-gray-600">DEBORAH OKORIE</p>
            </div>
            </section>
        </div>
    )
}

export default Testimonies;