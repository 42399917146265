 import React,{useState} from 'react';
 import Header from './Header';
 import ImagesSection from './ImagesSection';
import './App.css';
import Services from './Services';
import MedicalServices from './MedicalServices';
import Testimonies from './Testimonies';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { metadata } from './metadata';
 
 

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`${isOpen ? "App fixed w-full h-full bg-gray-200" : "App  w-full h-full bg-gray-200"}`}>
       <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        
      </Helmet>
       
     <Header isOpen={isOpen} toggleMenu={toggleMenu} />
     <ImagesSection/>
     <Services/>
     <MedicalServices/>
     <Testimonies/>
     <Footer/>
    </div>
  );
}

export default App;
