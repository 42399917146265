import React from "react";
import image1 from "./images/kidney-trans-SNH.jpg"
import image2 from "./images/st-nicholas-hospital-orthopaedics-1.jpg"
import image3 from "./images/hay-15.jpg"
function MedicalServices(){
    return(
    <div className="w-full mt-28 lg:mt-32 grid place-items-center" id="med">
       <h2 className=" md:text-4xl text-2xl text-black font-bold  leading-normal mb-24 ">Some Of Our Medical Services</h2> 

       <div className="lg:flex lg:place-items-start grid place-items-center justify-between lg:gap-24">

        <div className="mb-20 lg:mb-0">
        <img src={image1} className="lg:w-96 md:h-96  md:w-96 h-86 w-86 lg:rounded-full  rounded-full" />
        <p className="text-black  lg:ml-16 mt-10  font-bold text-xl">Labor and delivery services</p>
        </div>
        <div className="mb-20 lg:mb-0">
        <img src={image2} className="lg:w-96 md:h-96 md:w-96   h-86 w-86 lg:rounded-full  rounded-full" />
        <p className="text-black  lg:ml-16 mt-10  font-bold text-xl"> Maternal Health Services</p>
        </div>
        <div className="mb-20 lg:mb-0">
        <img src={image3} className="lg:w-96 md:h-96 md:w-96   h-86 w-86 lg:rounded-full  rounded-full" />
        <p className="text-black  font-bold lg:ml-16 mt-10 text-xl">Pharmarcy</p>
        </div>

        

         

       </div>
    </div>
    )
}

export default MedicalServices;