import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
 import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
 import logo from './images/download.png'
 


function Footer (){
    return(
        <section>
        <div className="footer-color py-12 lg:px-12  lg:py-12 mt-20 place-items-center       lg:flex lg:justify-between" id="contact">
            <section className="lg:flex gap-4 ">
                <div className=" lg:hidden grid place-items-center lg:place-items-start">
                <FontAwesomeIcon icon={faMapMarkerAlt}  className="text-2xl text-blue-400"  />
               
                </div>
                <div>
                    <h2 className="text-white font-semibold grid place-items-center lg:place-items-start text-lg mt-4 lg:mt-0">Address</h2>
                    <p className="  md:text-base text-sm   mt-2 font-semibold bold md:font-semibold text-white whitespace-nowrap lg:place-items-start grid place-items-center ">3rd Avenue, 321 Road, "C" Close,House 8 Festac Town,</p><p className=" text-white  font-semibold grid place-items-center lg:place-items-start md:text-lg mt-4 lg:mt-0    text-sm ">Lagos.</p>
    
                </div>
            </section>
            <section className=" lg:flex gap-4 ">
                <div className="grid place-items-center lg:place-items-start">
                <FontAwesomeIcon icon={faPhone}  className="text-2xl text-blue-400 mt-12 lg:mt-0"  />
               
                </div>
                <div>
                    <h2 className=" text-white font-semibold grid place-items-center lg:place-items-start text-lg mt-4 lg:mt-0">Call Us</h2>
                    <p className=" text-white font-semibold grid place-items-center lg:place-items-start text-lg mt-4 lg:mt-2 underline   ">09042142664</p>
                </div>
            </section>
            <section className="lg:flex  gap-4">
                <div className="grid place-items-center lg:place-items-start">
                <FontAwesomeIcon icon={faEnvelope}   className="text-2xl text-blue-400 mt-12 lg:mt-0"  />
               
                </div>
                <div className="grid place-items-center lg:place-items-start">
                <a className="text-white underline font-semibold mt-4 lg:mt-0 text-lg" href="mailto:info@bonnesantehealthservice.com" >info@bonnesantehealthservice.com</ a>
           
                </div>
            </section>
        </div>

      <div className="w-full grid place-items-center"> 

      <div className="list-border-bottom w-90% mt-6 mb-6"></div>
      </div>
         

         <div className="w-full pb-32 pt-14 grid place-items-center">
          <section className="flex gap-4 place-items-center">
            <div><img src={logo} className="lg:w-24 lg:h-20 w-16 h-14 rounded-md" /></div>
            <div><p className="lg:font-bold lg:text-2xl md:text-lg text-sm font-medium  grayish">BONNE SANTÉ HEALTH SERVICE LIMITED</p></div>
          </section>
         </div>
     

     <div className="w-full grid place-items-center pb-20">
        <section>
            <p className="underline  md:text-base text-sm font-medium">copyright  &copy; 2014 Bonne Santé  Health Service Limited  </p>
        </section>
     </div>
        </section>
    )
}

export default Footer;