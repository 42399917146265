import React, { useState } from 'react';
 import logo from './images/download.png'
 import { faBars } from '@fortawesome/free-solid-svg-icons';
 import { faTimes } from '@fortawesome/free-solid-svg-icons';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header ({ isOpen, toggleMenu }){
    
    return(
        <div className='relative w-full'>
            <header className="pt-4 md:ml-10 ml-2 md:mr-20 justify-between flex">
         <a href="#">    
       <div className="flex md:gap-6 gap-2 lg:gap-8  place-items-center lg:h-22  ">
        <img src={logo} className="lg:w-24 lg:h-20 w-14 h-12 rounded-md"/>
        <p className="lg:text-base text-xs md:font-semibold font-normal  grayish lg:font-bold"> BONNE SANTÉ HEALTH SERVICE LIMITED </p>
       </div>
       </a>  

       <div className="">
        <ul className='hidden lg:flex   h-22  gap-8 place-items-center '>

       <li><a className="text-lg   hover:text-red-400 text-red-500   font-medium" href="/" >Home</a></li> 
       <li> <a className="text-lg hover:text-red-500  grayish  font-medium" href="#med" >Services </a></li>
       <li> <a className="text-lg hover:text-red-500  grayish  font-medium" href="#about" >About Us</a></li>
       <li> <a className="text-lg hover:text-red-500  grayish font-medium" href="#contact" >Contact Us</a></li>
        </ul>
        <ul className={`menu  ${isOpen ? "open" : ""}`}>

        <div className="flex pt-4 pb-10  pl-2 md:gap-6 gap-2 lg:gap-8 w-full mobile-grayish place-items-center lg:h-22  ">
        <img src={logo} className="lg:w-24 lg:h-20 w-14 h-12 rounded-md"/>
        <p className="lg:text-base   text-xs md:font-semibold font-semibold  text-white lg:font-bold">BONNE SANTÉ HEALTH SERVICE LIMITED</p>
        <FontAwesomeIcon icon={faTimes}  className='text-xl absolute right-4 top-3 text-white' onClick={toggleMenu}/>
       </div>

     <div className=' mt-8'>
       <li onClick={toggleMenu} className='w-full h-14  list-border   grid place-items-center'><a className="text-base    grayish  font-medium" href="/" >Home</a></li> 
       <li onClick={toggleMenu} className='w-full h-14 list-border-bottom  grid place-items-center'> <a className="text-base  grayish w-full grid place-items-center font-medium" href="#med" >Services</a></li>
       <li onClick={toggleMenu} className='w-full h-14 list-border-bottom  grid place-items-center'> <a className="text-base  grayish w-full grid place-items-center font-medium" href="#about" >About Us</a></li>
       <li onClick={toggleMenu} className='w-full h-14 list-border-bottom  grid place-items-center'> <a className="text-base   grayish w-full grid place-items-center font-medium" href="#contact" >Contact Us</a></li>
       </div>
        </ul>

       </div>
    
            </header>
         <div className='lg:hidden block gap-4 absolute right-5 top-8' onClick={toggleMenu}>
         <FontAwesomeIcon icon={faBars}  className='text-xl grayish'/>
       
         </div>
        </div>
    )
}
    export default Header;