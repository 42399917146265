import React, { useState, useEffect } from 'react';

import firstImage from  "./images/updated.jpg"
import secondImage from "./images/FB_IMG_1657092937441.jpg";
import thirdImage from "./images/hospital-bed.jpg";

const allImages = [
    {
        id: 1,
        url: firstImage
    },
    {
        id: 2,
        url: secondImage
    },
    {
        id: 3,
        url: thirdImage
    },
   
];

function ImagesSection() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleDivs, setVisibleDivs] = useState([true, false, false, false]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % allImages.length;
                updateVisibleDivs(newIndex);
                return newIndex;
            });
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const updateVisibleDivs = (index) => {
        const newVisibleDivs = visibleDivs.map((_, i) => i === index);
        setVisibleDivs(newVisibleDivs);
    };

    return (
        <div className='mt-10'>
            {allImages.map((picture, index) => (
                <div key={picture.id} style={{ display: visibleDivs[index] ? 'block' : 'none' }}>
                    <img className="w-full h-97 lg:h-250" src={picture.url} alt={`Image ${index + 1}`} />
                </div>
            ))}
        </div>
    );
}

export default ImagesSection;
